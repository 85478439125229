.filterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 70%;
  background-color: rgba(197, 196, 196, 0.489);
  border-radius: 10px;

    .allButtonsContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100%;

        .ratingBtn {
          height: 10%;
          background-color: #2C666E;
          color: white;
        }

        button {
          width: 80%;
          height: 15%;
          border-radius: 5px;
          transition: all 0.1s;
          font-family: Poppins;
          text-transform: capitalize;
          font-size: 1.6rem;
          background-color: #ffffff54;
        }
        button:hover {
          cursor: pointer;
          background-color: #2C666E;
          transition: background-color 0.2s;
          color: white;

        }

        .active{
          cursor: pointer;
          background-color: #2C666E;
          transition: background-color 0.2s;
          color: white;
        }

        .active:hover {
          width: 80%;
          height: 15%;
          border-radius: 5px;
          transition: all 0.1s;
          font-family: Poppins;
          text-transform: capitalize;
          font-size: 1.6rem;
          background-color: #ffffff54;
        }

        .professionsButtonsContainer{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          overflow-y: scroll;
          align-items: center;
          gap: 10px;
          height: 80%;
          margin-top: 5%;
        }
    }
}




