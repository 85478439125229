$white-color: #F0EDEE;
$green-color: #0e4e52;
$lightgreen-color: #2C666E;
$darkgreen-color: #07393C;

.modalMainContainer {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* From https://css.glass */
  background: rgba(24, 24, 24, 0.40);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(24, 24, 24, 0.3);

    .modalContainer {
      width: 50%;
      height: 80%;
      border-radius: 16px;
      background-color: #F0EDEE;
      display: flex;
      
      .informationLeftSide, .informationRightSide {
        display: flex;
        flex-direction: column;
        width: 50%;
          label, input, h4 {
            font-size: 1.7rem;
            width: 80%;
          }
      }

      .informationLeftSide {
        justify-content: space-evenly;
        align-items: center;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: 10px;
          width: 90%;
        }
        
          
        label {
              text-decoration:underline;
            }
        }
      }

      .informationRightSide {
        justify-content: space-evenly;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90%;

          .editBtn {
            width: 80px;
            height: 30px;
            border-radius: 5px;
            color: $white-color;
            background-color: rgba(128, 128, 240, 0.534);
              &:hover{
                  color: $white-color;
                  background-color: rgba(92, 92, 238, 0.534);
              }
          }
          .containerLabelProfessions {
            display: flex;
            flex-direction: column;
            .removeProfessionsContainer{
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
              .removeProfessionBtn {
                width: 100px;
                border-radius: 5px;
                height: 20px;
                background-color: $green-color;
                color: $white-color;
              }
            }
          }


          .cancelBtn {
              width: 80px;
              height: 30px;
              border-radius: 5px;
              color: $white-color;
              background-color: rgba(175, 43, 43, 0.829);
                 &:hover{
                    color: $white-color;
                    background-color: rgb(190, 39, 39);
                 }
          }
        }
        div {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
            input {
              width: 80px;
              height: 30px;
            }
            .yesBtn {
              // width: 80px;
              // height: 30px;
              border-radius: 5px;
              color: $white-color;
              background-color: rgba(37, 168, 37, 0.829);
            }
            .noBtn {
              // width: 80px;
              // height: 30px; 
              border-radius: 5px;
              color: $white-color;
              background-color: rgba(175, 43, 43, 0.829);
                 &:hover{
                    color: $white-color;
                    background-color: rgb(190, 39, 39);
                 }
            }
        }
      }
    }
