.contenedorPadre{
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    overflow-y: auto;
}

.titleActivo{
    color: black;
    font-family: Poppins;
    font-size: 40px;
    margin-top: 10px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
}

.corona{
    width: 50px;
    height: 50px;
    margin-left: 15px;
}

.checksContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: -5%;
    margin-left: -2%;
}

.ulBeneficios{
    padding: 0;
}

li{
    display: flex;
    align-items: baseline;
    text-align: left;
}

li img{
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-left: 40px;
}

.beneficios{
    color: black;
    font-weight: 200;
    font-size: 30px;
}

.contenedorPremium{
    border-radius: 50px;
    margin-top: 20px;
    background-color: #2c666e;
    width: 80%;
    height: 45%;
    box-shadow: 0px 10px 20px -1px rgba(0,0,0,0.7);
}

.vence{
    margin-left: 6px;
    color: white;
    font-size: 26px;
    font-weight: 300;
    text-align: left;
    display: flex;
}

.vence img{
    width: 47px;
    height: 47px;
}

.id{
    margin-left: 10px;
    text-align: left;
    color: white;
    font-size: 25px;
    font-weight: 300;
    text-align: left;

}

.idP{
    text-align: left;
    margin-left: 10px;
    color: white;
    font-size: 28px;
    font-weight: 400; 
}

.pago{
    margin-top: 20px;
    color: white;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300; 
    text-align: left;
    margin-left: 15px;
}   

.bePremium {
    margin-top: 60px;
    height: 38%;
    margin-bottom: 13.4%;
    width: 90%;
    display: flex;
    align-content: space-around;
    justify-content: space-evenly;
    border-radius: 10px;
    background: #2c666e;
    padding: 5px;
    box-shadow: 0px 10px 20px -1px rgba(0,0,0,0.7);
}

.premiumText {
    width: 65%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.premiumText h1 {
    font-size: 3.8rem;
    line-height: 5rem;
    color: white;
    margin-left: 12px;
    margin-bottom: 20px;
}

.premiumRocketButton {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

}

.premiumRocketButton div {
    align-items: center;
    justify-content: flex-end;
    margin-left: 9%;
    margin-top: 7%;
    height: 100%;
    margin-bottom: 5%;
}

.premiumRocketButton div img{
    width: 220px;
    height: 220px;
}

.descuento{
    display: inline;
    text-align: left;
    justify-content: center;
    margin-left: 40px;
    font-size: 2.4rem;
    color: white;
}

.descuento img{
   width: 24px;
   height: 24px;
}

.descuento h3{
    display: inline;
    text-decoration: line-through;
    font-size: 24px;
    color: white;
    font-weight: 400;
} 

.contenedorPrecio{
    margin-left: 15%;
    margin-top: -0.8%;
    position: relative;
}

.contenedorPrecio img{
    position: absolute;
    top: -15px;
    left: 148px;
    width: 50px;
    height: 50px;
}

.contenedorPrecio h2{
    color: white;
    position: absolute;
    font-size: 24px;
    font-weight: 800;
    top: -34px;
    left: 190px;
}

.contenedorBotonBuy{
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    margin-top: 11%;
    font-weight: 400;
    font-size: 2rem;
    width: 220px;
    height: 36px;
    border-radius: 5px;
    background-color: #1a4349;
    color: #eeeeee;
    box-shadow: 0px 7px 15px -7px rgba(0,0,0,0.7);
}

.contenedorBotonBuy:hover {
    transition: 0.5s;
    scale: 1.02;
    cursor: pointer;
    color: #2c666e;
    background-color: #d1d1d1;
}

/* responsive tablets ---------------------------------------------------- */
@media screen and (max-width: 1100px) {

	.titleActivo{
        font-size: 3.4rem;
    }

    .corona{
        margin-left: 0%;
    }

    .checksContainer{
        margin-left: -1%;
    }

    .beneficios{
        font-weight: 200;
        font-size: 2.7rem;
    }

    .contenedorPrecio h2{
        line-height: 2.8rem;
    }

    .premiumRocketButton div img{
        width: 90%;
        height: 60%;
    }

    .contenedorBotonBuy{
        margin: 0 auto;
        margin-top: 4%;
        margin-left: 55%;
    }

}

/* responsive smartphones ----------------------------------------------*/
@media screen and (max-width: 650px) {

    .titleActivo{
        font-size: 3.1rem;
        line-height: 3.5rem;
    }

    .corona{
        margin-left: -6%;
    }

    .checksContainer{
        margin-left: -7%;
        margin-bottom: -9%;
    }

    .ulBeneficios{
        margin-top: -2%;
    }
    
    
    li img{
        width: 20px;
        height: 22px;
        margin-right: 10px;
        margin-top: 15px;
    }

    .beneficios{
        font-weight: 200;
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: -7%;
    }

    .bePremium {
        width: 85%;
        height: 150px;
        margin-left: 2%;
        margin-bottom: 0%;
    }

    .premiumText{
        margin-top: 4%;
    }

    .premiumText h1 {
        margin-top: -6%;
        font-size: 2.1rem;
        line-height: 2.6rem;
    }

    .premiumRocketButton div img{
        margin-top: -15%;
        height: 60px;
        width: 90%;
    }

    .descuento{
        margin-top: -8%;
        font-size: 1.5rem;
        margin-left: 8%;
        margin-right: -55%;
    }

    .premiumText h3{
        font-size: 1.7rem;
        display: none;
    }

    .contenedorBotonBuy{
        width: 80%;
        height: 28px;
        margin-left: 10%;
    }

    .contenedorBotonBuy p{
        font-size: 1.5rem;
    }

    .contenedorPrecio img{
        top: -5px;
        left: 122px;
        width: 32px;
        height: 33px;
        display: none;
    }
    
    .contenedorPrecio h2{
        font-size: 1.6rem;
        font-weight: 800;
        top: -25px;
        left: 57px;
    }
}
