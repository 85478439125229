.faq__desc.show-description {
  transition: 0.6s;
  height: max-content;
  padding-top: 3%;
  padding-bottom: 2%;
  margin-bottom: 4%;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
}

/* Arrows */

/* closed */
.faq__question-button::after {
  transition: transform 300ms ease-out;
}

/* open */
.font-weight-bold::after {
  transform: rotate(720deg);
}
