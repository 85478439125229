.faq {
  width: 100%;
  margin: -5% 8% 1% 35%;
  display: flex;
  justify-content: center;
  text-align: center;

  h2 {
    color: $page-color;
    font-size: 5rem;
  }

  &__list {
    padding-top: 12%;
    margin-left: -5%;
  }

  &__question {
    max-width: 350px;
    width: 100%;
    margin-left: -55%;
    border-bottom: 1px solid $dark-grayish-green;
    border-bottom-width: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 1%;
  }

  &__question-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    font-size: 1.9rem;
    padding-top: 8%;

    &::after {
      content: url(../assets/icon-arrow-down.svg);
      margin-left: 5%;
      margin-right: -10%;
      transform: rotate(90deg);
    }

    &:hover {
      color: $page-color;
    }
  }

  &__desc {
    margin: 0% -75% 0% -50%;
    padding-right: 17px;
    height: 0;
    font-size: 2rem;
    overflow: hidden;
    transition: all 200ms ease-out;
  }
}

//tablets
@media screen and (max-width: 1100px) {

	.faq{
		margin: -5% 0% 1% 40%;

    &__list {
      padding-top: 7%;
      margin-left: -8%;
    }

    &__desc {
      margin: 0% -55% 0% -35%;
    }
  }
}

//smartphones
@media screen and (max-width: 700px) {

	.faq{
    margin-left: 41%;

    &__list {
      padding-top: 28%;
      margin-left: -17%;
    }

    &__desc {
      font-size: 1.8rem;
      margin: 0% -39% 0% -25%;
    }
  }
}
