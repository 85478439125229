.order{
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin-top: 5%;
    min-width: 80%;
    min-height: 20%;
    background-color: #EEEEEE;

    .info{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;

        .img{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25%;
            height: 100%;

            img{
                width: 60%;
                height: 65%;
                border-radius: 50%;
                border: 10px solid rgba(190, 190, 190, 0.475);
                object-fit: cover;
            }
        }

        .userdata{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            height: 60%;
            .name{
                text-transform: capitalize;
                font-weight: 400;
                font-size: 2.2rem;
            }

            .location{
                color: #4e4e4e;
                font-size: 1.6rem;
            }
        }

        .btndiv{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 25%;
            height: 100%;

            .btn{
                transition: 0.3s;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                width: 70%;
                height: 40%;
                font-size: 1.6rem;
                font-weight: 400;
                background-color: #1c464c;
                color: #EEEEEE;

                &:hover{
                    transition: 0.3s;
                    background-color: #EEEEEE;
                    color: #0e2427;
                    border-radius: 5px;
                }
            }
        }

        &:hover{
            transition: 0.3s;
            cursor: pointer;
            background-color: #2C666E;

            .userdata{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 50%;
                height: 60%;
                .name{
                    color: #EEEEEE;
                    text-transform: capitalize;
                    font-weight: 400;
                    font-size: 2.2rem;
                }

                .location{
                    color: #b7b7b7;
                    font-size: 1.6rem;
                }
            }
        }
    }

    .opinion{
        width: 100%;
        height: 55%;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.review{
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    margin-top: 5%;
    min-width: 80%;
    min-height: 60%;
    background-color: #EEEEEE;

    .info{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 40%;

        .img{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25%;
            height: 100%;

            img{
                width: 60%;
                height: 65%;
                border-radius: 50%;
                border: 10px solid rgba(190, 190, 190, 0.475);
                object-fit: cover;
            }
        }

        .userdata{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            height: 60%;
            .name{
                text-transform: capitalize;
                font-weight: 400;
                font-size: 2.2rem;
            }

            .location{
                color: #4e4e4e;
                font-size: 1.6rem;
            }
        }

        .btndiv{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 25%;
            height: 100%;

            .btn{
                transition: 0.3s;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                width: 70%;
                height: 30%;
                font-size: 1.6rem;
                cursor: pointer;
                font-weight: 400;
                background-color: #1c464c;
                color: #EEEEEE;

                &:hover{
                    transition: 0.3s;
                    background-color: #EEEEEE;
                    color: #0e2427;
                    border-radius: 5px;
                }
            }
        }
    }

    .opinion{
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .form{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .inputs{
                width: 100%;
                height: 70%;
                display: flex;
                justify-content: center;
                align-items: center;

                .barra{
                    width: 40%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    label{
                        margin-top: 5px;
                        font-size: 2.0rem;
                    }

                    input{
                        margin-bottom: 15%;
                    }

                    p{
                        font-size: 1.6rem;
                    }
                }

                .description{
                    width: 60%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;


                    label{
                        margin-top: 5px;
                        font-size: 2.0rem;
                    }

                    textarea{
                        padding: 5px;
                        font-family: Poppins;
                        font-size: 1.6rem;
                        margin-bottom: 5px;
                        width: 90%;
                        height: 60%;
                    }
                }
            }

            .submit{
                width: 100%;
                height: 30%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btnSubmit{
                    transition: 0.3s;
                    background-color: #1c464c;
                    width: 25%;
                    height: 70%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.6rem;
                    color: #b7b7b7;
                    border-radius: 10px;
                    font-weight: 500;

                    &:hover{
                        cursor: pointer;
                        transition: 0.3s;
                        background-color: #EEEEEE;
                        color: #0e2427;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .order{
      min-height: 10%;
    }


    .review{
      .info{
        min-height: 37%;
        .userdata{
          .name{
            font-size: 2.5rem;
          }
          .location{
            font-size: 1.8rem;
          }
        }
        .img{
          height: 70%;
          width: 36%;
        }
      }
      .btndiv{
        max-height: 68.5%;
      }
      .opinion{
        .form{
          margin-top: -5%;
          .inputs{
            .description{
              label{
                font-size: 2.2rem;
              }
              h3{
                font-size: 1.8rem;
              }
            }
            .barra{
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }