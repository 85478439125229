.searchBar {
	display: flex;
	flex-direction: column;
	width: 80%;
	gap: 2px;
	align-items: center;

		h3 {
			width: 100%;
			text-align: center;
			font-size: 1.6rem;
			align-self:flex-start;
		}

		input {
			text-align: center;
			padding: 5px;
			width: 100%;
			height: 30px;
			border-radius: 5px;
			background-color: transparent;
			border-bottom: 1px solid #2C666E;
			margin-bottom: 5%;

			&:focus{
				outline: none;
			}
		}
}