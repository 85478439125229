.professionList {
    overflow-y: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 70%;
    width: 80%;
    margin-left: 4.8%;
    padding-left: 5%;
    padding-right: 5%;
  }


.imagenPremium{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  width: 500px;
  height: 500px;
}