.container {
    overflow-y: auto;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;

    .inputDiv {
        margin: 1%;
        height: 8%;
        width: 30%;
        transition: 0.2s;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        color: black;
        text-decoration: none;
        font-size: 1.4rem;
        margin-inline: 3%;

        div {
            width: 40%;
        }

        input {
            margin-left: 10px;
            font-size: 1.4rem;
            // line-height: 3rem;
            min-width: 192px;
            padding: 8px;
            border-radius: 0.5rem;
            background: rgba(255, 255, 255, 0.596);
            border: solid rgba(136, 136, 136, 0.356);
            border-width: 1px;
        }

        select {
            min-width: 192px;
            width: 122%;
            margin-left: 10px;
            font-size: 1.4rem;
            // line-height: 3rem;
            padding: 8px;
            border-radius: 0.5rem;
            background: rgba(255, 255, 255, 0.596);
            border: solid rgba(136, 136, 136, 0.356);
            border-width: 1px;
        }



    }



    .premiumSpam {
        font-weight: 300;
        font-size: 2rem;
        line-height: 3.2rem;
    }

    .submit {
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15%;
        height: 5%;
        border-radius: 5px;
        background-color: #07393C;
        color: #f5f5f5;

        &:disabled {
            background-color: rgba(168, 168, 168, 0.47);
            color: #07393c80;
        }

        &:not(:disabled):hover {
            transition: 0.3s;
            cursor: pointer;
            color: #f5f5f5;
            background-color: #07393c46;
            scale: 1.05;
        }
    }

    .professionList {
        margin-right: 5%;
        overflow-y: auto;
        display: flex;
        // justify-content: space-evenly;
        align-items: flex-start;
        flex-direction: column;
        height: 75%;
        width: 80%;
        margin-left: 4.8%;
        padding-left: 5%;
        padding-right: 5%;

        .individualProfession {
            background: #F0EDEE;
            margin-top: 3%;
            margin-bottom: 3%;
            height: 35%;
            max-height: 150px;
            width: 100%;
            max-width: 600px;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.5s;

            &:hover {
                transition: 0.5s;
                scale: 1.04;
            }

            img {
                margin-right: 2%;
                transition: 0.5s;

                &:hover {
                    transition: 0.5s;
                    scale: 1.1;
                }
            }
        }
    }


    .premiumSpam {
        font-weight: 300;
        font-size: 2rem;
        line-height: 3.2rem;
        color: #0031b6;
    }

    .submit {
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15%;
        height: 5%;
        border-radius: 5px;
        background-color: #07393C;
        color: #f5f5f5;

        &:disabled {
            background-color: rgba(168, 168, 168, 0.47);
            color: #07393c80;
        }

        &:not(:disabled):hover {
            transition: 0.3s;
            cursor: pointer;
            color: #f5f5f5;
            background-color: #07393c46;
            scale: 1.05;

        }
    }


    .notfound{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;

        p{
            transition: 0.3s;
            color: rgba(70, 70, 70, 0.583);
            text-decoration: underline;
            font-size: 2.0rem;

            &:hover{
                transition: 0.3s;
                cursor: pointer;
                scale: 1.02;
                color: rgba(40, 40, 40, 0.583);
            }
        }

    }
}