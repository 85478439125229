.message{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.messageTop{
    display: flex;
    flex-direction: row;
}

.messageImg{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    align-self: flex-end;
}


.messageText{
    padding: 10px;
    margin-bottom: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-end-end-radius: 20px;
    background-color: #07393C;
    color: white;
    font-size: 20px;
    max-width: 300px;
    word-wrap: break-word;
}

.messageBottom{
    font-size: 12px;
    margin-top: 10px;
}


.message.own{
    align-items: flex-end;
}

.message.own .messageText{
    background-color: rgb(223, 223, 223);
    color: black;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-end-start-radius: 20px;
    border-end-end-radius: 0px
}