.professionList {
  overflow-y: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 70%;
  width: 80%;
  margin-left: 4.8%;
  padding-left: 5%;
  padding-right: 5%;

  .individualProfession {
    background: #F0EDEE;
    margin-top: 3%;
    margin-bottom: 3%;
    height: 35%;
    max-height: 150px;
    width: 100%;
    max-width: 600px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      scale: 1.04;
    }
  }
}

@media screen and (max-width: 1100px) {
  
}