.order {
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  margin-top: 5%;
  min-width: 80%;
  min-height: 20%;
  background-color: #EEEEEE;

  .info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;

      img {
        width: 60%;
        height: 65%;
        border-radius: 50%;
        border: 10px solid rgba(190, 190, 190, 0.475);
        object-fit: cover;
      }
    }

    .userdata {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      height: 60%;

      .name {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 2.2rem;
      }

      .location {
        color: #4e4e4e;
        font-size: 1.6rem;
      }
    }

    .btndiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;

      .btn {
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 70%;
        height: 40%;
        font-size: 1.6rem;
        font-weight: 400;
        background-color: #1c464c;
        color: #EEEEEE;

        &:hover {
          transition: 0.3s;
          background-color: #EEEEEE;
          color: #0e2427;
          border-radius: 5px;
        }
      }
    }

    &:hover {
      transition: 0.3s;
      cursor: pointer;
      background-color: #2C666E;

      .userdata {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 60%;

        .name {
          color: #EEEEEE;
          text-transform: capitalize;
          font-weight: 400;
          font-size: 2.2rem;
        }

        .location {
          color: #b7b7b7;
          font-size: 1.6rem;
        }
      }
    }
  }

  .opinion {
    width: 100%;
    height: 55%;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.review {
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  margin-top: 5%;
  min-width: 80%;
  min-height: 40%;
  background-color: #EEEEEE;

  .info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60%;

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;

      img {
        width: 60%;
        height: 65%;
        border-radius: 50%;
        border: 10px solid rgba(190, 190, 190, 0.475);
        object-fit: cover;
      }
    }

    .userdata {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
      height: 60%;

      .name {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 2.2rem;
      }

      .location {
        color: #4e4e4e;
        font-size: 1.6rem;
      }
    }

    .btndiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;

      .btn {
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 70%;
        height: 30%;
        font-size: 1.6rem;
        cursor: pointer;
        font-weight: 400;
        background-color: #1c464c;
        color: #EEEEEE;

        &:hover {
          transition: 0.3s;
          background-color: #EEEEEE;
          color: #0e2427;
          border-radius: 5px;
        }
      }
    }
  }

  .opinion {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      .inputs {
        width: 100%;
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .barra {
          width: 90%;
          height: 20%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 5px;
          font-size: 1.4rem;
        }

        .description {
          width: 90%;
          height: 70%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          padding: 5px;
          font-family: Poppins;
          text-align: justify;

          label {
            margin-top: 5px;
            font-size: 1.7rem;
          }

          h3 {
            font-weight: 300;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .order{
    min-height: 10%;
  }
  .review{
    .info{
      min-height: 37%;
      .userdata{
        .name{
          font-size: 2.5rem;
        }
        .location{
          font-size: 1.8rem;
        }
      }
      .img{
        height: 73%;
        width: 30%;
      }
    }
    .opinion{
      .form{
        margin-top: -5%;
        .inputs{
          .description{
            label{
              font-size: 2.2rem;
            }
            h3{
              font-size: 1.8rem;
            }
          }
          .barra{
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}