.container {
	animation: init 0.3s;
	width: 100%;
	height: 90%;
	display: flex;

	.aside, .asideInactive {
		width: 20%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h1{
			font-weight: 300;
			font-size: 2.8rem;
		}
		.showAllBtn {
			transition: 0.5s;
			background-color: #2C666E;
			width: 80%;
			color: white;
			height: 30px;
			border-radius: 3px;
			margin-top: 20px;
			align-self: center;

			&:hover{
				cursor: pointer;
				transition: 0.5s;
				color: #2C666E;
				background-color: white;
			}
		}

		.filtersFormMainContainer {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: center;
			height: 95%;

			.searchButton {
					margin-top: 5%;
					transition: 0.5s;
					width: 80%;
					height: 40px;
					border-radius: 3px;
					background-color: #2C666E;
					color: white;

					&:hover{
						cursor: pointer;
						transition: 0.5s;
						color: #2C666E;
						background-color: white;
					}
			}
		}
	}

	.professionals{
		width: 80%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.slider{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
		}

		.header {
			width: 100%;
			color: white;
			font-size: 3.4rem;
			background-image: url('./assets/header.svg');
			text-align: center;

			span {
				line-height: 20vh;
			}
		}

		.paginate {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 10px;
			
			h3{
				display: none;
			}
		}

		.cardsContainer {
			overflow-y: scroll;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			flex-wrap: wrap;
			width: 100%;
			height: 100%;
			margin: 10px 0 10px 0;

			.notFind {
				font-size: 3rem;
				font-weight: 250;
			}
		}
	}
}

@media screen and (max-width: 1300px){
	.container{
		position: relative;
		.aside{
			transition: 0.4s;
			width: 60%;
			z-index: 10;
			background-color: white;
			top: 0;
			position: absolute;

			h2{
				font-size: 2.0rem;
				margin-left: 10px;
				color: #2C666E;
			}
		}

		.asideInactive{
			transition: 0.4s;
			width: 60%;
			z-index: 10;
			background-color: white;
			top: 0;
			position: absolute;
			transform: translateX(-100vw);
		}

		.professionals{
			width: 100%;
			
			.paginate{
				h3{
					transition: 0.5s;
					border-radius: 5px;
					width: 125px;
					background-color: #2C666E;
					color: white;
					font-size: 3.2rem;
					font-weight: 200;
					display: flex;
					justify-content: center;
					align-items: center;

					&:hover{
						cursor: pointer;
						scale: 1.02;
						transition: 0.5s;
						background-color: white;
						color: #2C666E;
					}
				}

				.active{
					background-color: rgb(22, 22, 22);
					color: #526e72;
				}
			}
		}
	}
}


@media screen and (max-width: 900px){
	.container{
		.professionals{
			.cardsContainer{
				overflow-y: scroll;
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				justify-content: space-evenly;
				align-items: center;
			}
		}

	}
}

@media screen and (max-width: 700px){
	.container{
		.professionals{
			.header{
				span{
					font-size: 2.2rem;
				}
			}
		}
		.aside{
			width: 100%;
		}

		.asideInactive{
			width: 100%;
		}

	}
}


@keyframes init {
    0%{
        opacity: 0;
      transform: translateY(5vh);
    }
    100%{
        opacity: 1;
      transform: translateY(0);
    }
  }