.container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 35%;
    height: 100%;

    .professionalImg{
        margin-top: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 55%;
        height: 35%;

        img{
            border: 10px solid rgba(155, 155, 155, 0.311);
            width: 80%;
            height: 90%;
            border-radius: 50%;
            object-fit: cover;
        }

        .imagePremium{
            border: 9px solid #fdc644;
            box-shadow: 0px 0px 27px 7px #FFF780;
            width: 80%;
            height: 90%;
            border-radius: 50%;
            object-fit: cover;
        }

    }

    .professionaldata{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40%;

        .corona{
            width: 30px;
            height: 30px;
            margin-left: 5px;
          }


        .professionalName{
            text-transform: capitalize;
            color: #222222;
            font-size: 3.2rem;
        }

        .professionalCity{
            text-transform: capitalize;
            color: #646464;
            font-size: 2.4rem;
        }

        .professionalDescription{
            margin-top: 40px;
            text-transform: capitalize;
            text-align: center;
            color: #646464;
            font-size: 1.6rem;
            overflow-y: scroll;
        }
    }

    .generateOrder{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 25%;

        p{
            margin-top: 10px;
            text-align: center;
            color: #2c666e;
            margin-inline: 15%;
            font-size: 2.0rem;
        }

        .generatebtn{
            transition: 0.3s;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 2.0rem;
            color: #eeeeee;
            background-color: #2c666e;
            width: 30%;
            height: 30%;
            box-shadow: 9px 10px 15px -3px rgba(0,0,0,0.1);
            border-radius: 10px;

            &:hover{
                cursor: pointer;
                transition: 0.3s;
                scale: 1.02;
                border: 1px solid #2c666e;
                background-color: #eeeeee;
                color: #2c666e;
            }
        }

    }
}

@media screen and (max-width: 1100px){
    .container{
        display: none;
    }
}