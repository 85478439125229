.container {
  animation: init .8s;
  height: 90%;
  width: 100%;
  display: flex;
  background: rgba(217, 217, 217, 0.2);

  .leftContainer {
    margin-top: 1.5%;
    margin-bottom: 1%;
    margin-right: -2.5%;
    height: 97.5%;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .profileInfo {
      height: 25%;
      display: flex;
      flex-direction: row;
      margin-bottom: 10%;
      width: 90%;

      .profile_Img_container {
        display: flex;
        width: 30%;
        height: auto;

        justify-content: center;
        align-items: center;

        .profile_Img {
          width: 55%;
          height: 80%;
          border-radius: 50%;
          border: 15px solid rgba(0, 0, 0, 0.082);
          object-fit: cover;
        }
      }

      .profile_Img_containerPremium {
        display: flex;
        width: 30%;

        justify-content: center;
        align-items: center;

        .profile_ImgPremium {
          width: 55%;
          height: 80%;
          border-radius: 50%;
          border: 9px solid #fdc644;
          box-shadow: 0px 0px 27px 7px #FFF780;
          object-fit: cover;
        }
      }

      .profileDetail {
        width: 70%;

        .name {
          text-transform: capitalize;
          font-size: 4.4rem;
          line-height: 5rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #000000;
        }

        .corona {
          width: 50px;
          height: 50px;
          margin-right: 5px;
        }

        .location {
          font-size: 2.3rem;
          line-height: 3rem;
          /* identical to box height */
          color: rgba(0, 0, 0, 0.58);
          margin-bottom: 2rem;
        }

        .description {
          font-weight: 200;
          text-align: justify;
          font-size: 1.7rem;
          line-height: 3rem;
          color: #000000;
          width: 95%;
        }
      }

    }

    .orderBox {

      height: 50%;
      width: 90%;
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;
      align-content: space-around;
      // justify-content: space-evenly;
      border-radius: 10px;
      background: #D9D9D9;

      .orderText {
        font-weight: 300;
        margin-top: 2%;
        margin-left: 4.9%;
        font-size: 3.2rem;
        line-height: 5.4rem;
        color: #000000;
      }

      .lastOrders {
        height: 70%;
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 5%;
        padding-right: 5%;
        align-items: center;
        justify-content: space-between;
      }

    }

    .configBox {
      display: flex;
      margin-bottom: 3%;
      margin-top: 2%;
      height: 10%;
      width: 100%;
      justify-content: left;
      align-items: center;


      .configImg {
        margin-left: 5%;
        width: 3.5%;
      }

      .configText {
        margin-left: 4%;

        transition: 0.5s;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        text-decoration: none;
        font-size: 1.6rem;
        margin-inline: 3%;


      }
    }

  }
}


.rightContainer {
  // border: solid black;
  margin-top: 1.5%;
  margin-bottom: 1%;
  height: 97.5%;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .professionContainer {
    height: 55%;
    width: 90%;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    align-content: space-around;
    // justify-content: space-evenly;
    border-radius: 10px;
    background: #D9D9D9;

    .professionText {

      font-weight: 300;
      margin-top: 2%;
      margin-left: 4.9%;
      font-size: 3.2rem;
      line-height: 5.4rem;
      color: #000000;
    }

    .professionList {
      height: 70%;
      width: 80%;
      margin-left: 4.8%;
      display: flex;
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%;
      align-items: center;
      justify-content: space-evenly;


    }

    .addProfession {
      height: 20%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 2%;
      margin-bottom: 2%;

      div {
        background-color: white;
        border-radius: 50%;
        padding: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s;

        &:hover {
          transition: 0.5s;
          scale: 1.04;
        }
      }

      img {
        object-fit: cover;
        transition: 0.5s;

        &:hover {
          transition: 0.5s;
          scale: 1.04;
        }

      }
    }

    .noProfessional {
      height: 80%;
      width: 80%;
      margin-left: 10%;
      display: flex;
      justify-content: center;
      font-size: 3.5rem;

      .intNoProf {
        margin-top: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        background-color: #f5f5f5;
        height: 25%;
        width: 85%;
        transition: 1s;
        text-decoration: none;
        color: #000000b1;

        &:hover {
          color: #f5f5f5a3;
          transition: 1s;
          scale: 1.05;
          background-color: #07393C;
        }
      }
    }

  }

  .bePremium {
    height: 25%;
    margin-bottom: 15.4%;
    width: 90%;
    display: flex;
    align-content: space-around;
    // justify-content: space-evenly;
    border-radius: 10px;
    background: #D9D9D9;

    .premiumText {
      width: 60%;
      display: flex;
      flex-direction: column;

      h1 {
        font-weight: 300;
        margin-top: 2%;
        margin-left: 4.9%;
        font-size: 3.2rem;
        line-height: 5.4rem;
        color: #000000;
      }

      .premiumDetail {
        overflow-y: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 2%;

        h4 {
          margin-left: 4.9%;
          margin-top: 2%;
          text-align: justify;
          font-weight: 200;
          font-size: 1.7rem;
          line-height: 2.5rem;
          color: #000000;
        }
      }

    }

    .premiumRocketButton {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      div {
        align-items: center;
        justify-content: flex-end;
        margin-left: 9%;
        height: 40%;
        margin-bottom: 5%;
      }

      span {
        width: 43%;
        background: #07393C;
        border-radius: 10px;
        margin-left: 4.9%;
        margin-bottom: 3%;
        margin-top: 5%;
        color: #000000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.5s;

        &:hover {
          transition: 0.5s;
          background: #3e909b;
        }

        button {
          background-color: #d9d9d900;
          font-weight: 100;
          font-size: 1.7rem;
          line-height: 2.8rem;
          color: #ffffffc0;
        }
      }
    }
  }

  // PREMIUN TRUE

  .isPremiumTRUE {
    height: 25%;
    margin-bottom: 15.4%;
    width: 90%;
    display: flex;
    align-content: space-around;
    // justify-content: space-evenly;
    border-radius: 10px;
    background: #D9D9D9;
    box-shadow: 0px 0px 6px 4px #FFF780;

    .premiumTextTRUE {
      width: 60%;
      display: flex;
      flex-direction: column;

      h1 {
        font-weight: 300;
        margin-top: 2%;
        margin-left: 4.9%;
        font-size: 3.2rem;
        line-height: 5.4rem;
        color: #000000;
      }

      .premiumDetailTRUE {

        overflow-y: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 2%;

        h4 {
          margin-left: 4.9%;
          margin-top: 2%;
          text-align: justify;
          font-weight: 200;
          font-size: 1.7rem;
          line-height: 2.5rem;
          color: #000000;
        }
      }

    }

    .premiumRocketButtonTRUE {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .rocketsito {
        width: 120px;
        height: 120px;
      }

      div {
        align-items: center;
        justify-content: flex-end;
        margin-left: 9%;
        height: 40%;
        margin-bottom: 5%;
      }

      span {
        width: 43%;
        background: #07393C;
        border-radius: 10px;
        margin-left: 4.9%;
        margin-bottom: 3%;
        margin-top: 5%;
        color: #000000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.5s;

        &:hover {
          transition: 0.5s;
          background: #3e909b;
        }
      }
    }
  }
}


//Tablets
@media screen and (max-width: 1100px) {
  .container {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .leftContainer {
      width: 80%;
      min-height: 60vh;
      margin-right: 0;
      height: auto;

      .profileInfo {
        height: 40%;
        width: 100%;
        margin-bottom: 3%;

        .profileDetail {
          width: 70%;

          .name {
            text-transform: capitalize;
            font-size: 4.4rem;
            line-height: 5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #000000;
          }

          .corona {
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }

          .description {
            font-weight: 280;
            font-size: 2rem;
          }
        }
        .profile_Img_container {
          min-width: 45%;
          height: 70%;
        }
  
      }


      .profile_Img_containerPremium {
        min-width: 40%;
        height: 70%;
      }


      .orderBox {
        display: flex;
        height: 60%;
        width: 100%;

        .lastOrders {
          // flex-direction: column;
          height: 85%;
          justify-content: space-evenly;
          overflow-y: auto;
        }

      }

      .configBox {
        display: none;
      }
    }

    .rightContainer {
      margin-top: 5%;
      width: 80%;
      min-height: 75vh;

      .professionContainer {
        width: 100%;

        .noProfessional {
          height: 80%;
          width: 80%;
          margin-left: 10%;
          display: flex;
          justify-content: center;
          font-size: 3.8rem;

          .intNoProf {
            height: 30%;
            width: 95%;
          }
        }
      }

      .isPremiumTRUE {
        height: 20vh;
        width: 100%;

        h1 {
          font-weight: 300;
          margin-top: 2%;
          margin-left: 6%;
          font-size: 4rem;
          line-height: 5.4rem;
        }

        .premiumDetailTRUE {
          h4 {
            font-size: 1.8rem;
          }
        }
      }

      .bePremium {
        height: 25%;
        margin-bottom: 15.4%;
        width: 100%;
        display: flex;
        align-content: space-around;
        // justify-content: space-evenly;
        border-radius: 10px;
        background: #D9D9D9;
    
        .premiumText {
          width: 65%;
          display: flex;
          flex-direction: column;
    
          h1 {
            font-size: 3.8rem;
            line-height: 5rem;
          }
    
          .premiumDetail {
    
            h4 {
              font-size: 1.8rem;
              line-height: 2.5rem;
            }
          }
    
        }
    
        .premiumRocketButton {
          width: 40%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
    
          div {
            align-items: center;
            justify-content: flex-end;
            margin-left: 9%;
            height: 40%;
            margin-bottom: 5%;
          }
    
          span {
            width: 50%;
            height: 20%;
    
            button {
              background-color: #d9d9d900;
              font-weight: 200;
              font-size: 2rem;
              line-height: 2.8rem;
              color: #ffffffc0;
            }
          }
        }
    }
    }
  }


}

@keyframes init {
  0%{
      opacity: 0;
    transform: translateY(5vh);
  }
  100%{
      opacity: 1;
    transform: translateY(0);
  }
}
