.messenger{
    position: fixed;
    width: 100%;
    height: 90%;
    display: flex;
    position: fixed;
}


.chatMenu{
    width: 25%;
    height: 100%;
    overflow-y: scroll;

}

.headerChats{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    width: 100%;
    height: 10%;
    font-size: 2.4rem;

}

.chatBox{
    width: 40%;
    height: 95%;
    padding: 10px;
    margin: 10px;
    background-color: #ffffff;
    border-radius: 20px;
}

.chatBoxWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.chatBoxTop{
    height: 100%;
    overflow-y: scroll;
    padding-right: 10px;
}

.chatBoxBottom{
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgb(171, 169, 169);
}

.openButton{
    display: none;
}

.chatMessageInput{
    border-radius: 0 0 10px 10px;
    font-family: Poppins;
    font-size: 1.6rem;
    width: 85%;
    height: 90px;
    max-width: 85%;
    max-height: 90px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #EEEEEE;
    vertical-align: top;
}

.chatSubmitButton{
    width: 70px;
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #2C666E;
    color: white;
    font-size: 20px;
}

.chatOnline{
    flex: 3
}

.closeChatmenu{
    display: none;
}

.chatMenuWrapper, .chatBoxWrapper, .chatOnlineWrapper{
    height: 100%;
}

.noConversationText{
    position: absolute;
    top: 20%;
    font-size: 50px;
    color: #2C666E;
    text-align: center;
    cursor: default;
}

@media screen and (max-width: 1100px){

    .chatMenu{
        width: 45%;
    }

    .chatBoxBottom{
        flex-direction: row-reverse;
    }

    .chatBox{
        width: 55%;
    }
}

@media screen and (max-width: 700px){

    .messenger{
        flex-direction: column;
    }

    .chatMenu{
        transition: 0.4s;
        width: 90vw;
        z-index: 2;
        top: 0;
        position: absolute;
        background-color: white;
    }

    .closeChatmenu{
        display: flex;
        font-size: 3.0rem;
        margin-top: 5px;
        margin-left: 20px;
        cursor: pointer;
    }

    .inactiveChat{
        transition: 0.4s;
        transform: translateX(-100vw);
    }

    .chatBox{
        z-index: 1;
        width: 95%;
        height: 90%;
    }

    .chatBoxBottom{
        flex-direction: row-reverse;
    }

    .openButton{
        display: flex;
        width: 100%;
        min-height: 10%;
        justify-content: center;
        align-items: center;
    }

    .openbtn{
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        width: 20%;
        height: 60%;
        font-size: 2.4rem;
        background-color: #2C666E;
        color: #EEEEEE;
    }

    .openbtn:hover{
        transition: 0.5s;
        scale: 1.04;
        color: #2C666E;
        background-color: #EEEEEE;
        cursor: pointer;
    }
}