.orders {
    width: 45%;
    height: 35%;
    background: #07393C;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;

    .imgDetail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 90%;

      img{
        width: 80%;
        height: 90%;
        object-fit: cover;
        border-radius: 50%;
      }

    }

    .orderDetail {
      margin: 5px;
      width: 40%;

      h1 {
        text-transform: capitalize;
        font-weight: 230;
        font-size: 2rem;
        line-height: 3rem;
        color: #F0EDEE;
      }

      h2 {
        text-transform: capitalize;
        font-weight: 230;
        font-size: 1.5rem;
        line-height: 2rem;
        color: rgba(217, 217, 217, 0.63);
      }


    }

    .orderDetail2 {
      h3 {
        font-weight: 230;
        font-size: 2rem;
        line-height: 5.4rem;
        color: #F0EDEE;
      }
    }

    
    &:hover{
      transition: 0.5s;
      scale: 1.04;
  }
  }

  // TABLET ----> IPAD AIR
  @media screen and (max-width: 1100px) {
    .orders{
      width: 90%;
      height: 40%;
      margin-top: 10px;
      margin-bottom: 10px;
      transition: 0.5s;
      .imgDetail{
        width: 20%;
        height: 75%;
        img{
          width: 100%;
        }
      }
      .orderDetail{
        width: 45%;
        margin-left: 5%;
        h1{
          font-size: 2.5rem;
          line-height: 4rem;
        }
        h2{
          font-size: 2.0rem;
          line-height: 4rem;
        }
      }
      .orderDetail2{
        h3{
          font-size: 2.3rem;
        }
      }
      &:hover{
        transition: 0.5s;
        scale: 1.04;
    }
    }
  }