.contenedor{
    background-color: #07393C;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
}

.title{
    padding-top: 40px;
    font-size: 3.2rem;
    font-family: Poppins;
    color: white;
}

.remember{
    padding-top: 70px;
    font-size: 4rem;
    font-weight: 400;
    color: white;
    font-family: Poppins;
}

.contenedorLista{
    text-align: center;
    width: 100%;
}

ul {
    padding-top: 70px;
    list-style: none;
    display: inline-block;
}

li{
    display: flex;
    text-decoration: none;
    font-size: 2.9rem;
    font-weight: 200;
    color: white;
    font-family: Poppins;
    align-content: flex-start;
    margin-top: 20px;
}

.content-input input,
.content-select select{
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
 
.content-input input{
    margin-top: -7px;
	visibility: hidden;
    display: flex;
	justify-content: center;
}

.content-input{
    cursor: pointer;
	position: relative;
    text-align: left;
    align-items: flex-start;
	margin-bottom: 30px;
	padding:5px 0 7px 60px; /* Damos un padding de 60px para posicionar 
        el elemento <i> en este espacio*/	
}
 
/* Estas reglas se aplicarán a todos las elementos i 
después de cualquier input*/
.content-input input + i{
       background: #dfdddd;
       border:2px solid rgba(0,0,0,0.2);
       position: absolute; 
       left: 0;
       top: 0;
}
 
/* Estas reglas se aplicarán a todos los i despues 
de un input de tipo checkbox*/
.content-input input[type=checkbox ] + i{
	width: 52px;
	height: 30px;
	border-radius: 15px;
}

.content-input input[type=checkbox] + i:before{
	content: ''; /* No hay contenido */
	width: 26px;
	height: 26px;
	background: #fff;
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	left: 1px;
	top: 2px;
	-webkit-box-shadow: 3px 0 3px 0 rgba(0,0,0,0.2);
	box-shadow: 3px 0 3px 0 rgba(0,0,0,0.2);
    transition: all 0.5s ease;
}

.content-input input[type=checkbox]:checked + i:before{
	left: 22px;
	-webkit-box-shadow: -3px 0 3px 0 rgba(0,0,0,0.2);
	box-shadow: 3px 0 -3px 0 rgba(0,0,0,0.2);
}
 
.content-input input[type=checkbox]:checked + i{
 background: #2AC176;
}

.content-input input[type=checkbox] + i:after{
	content: ''; /* No hay contenido */
	position: absolute;
    font-family: Poppins;
	font-size: 15px;
    font-weight: 400;
	color: rgb(255, 255, 255);
	top: 2px;
	left: 1px;
	opacity: 0 /* Ocultamos este elemento */;
	transition: all 0.25s ease 0.25s;
}
 
/* Cuando esté checkeado cambiamos la opacidad a 1 y lo mostramos */
.content-input input[type=checkbox]:checked + i:after{
 opacity: 1;
}

.contenedorBoton{
    margin-top: 30px;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 220px;
    height: 46px;
    border-radius: 5px;
    background-color: #2c666e;
    color: #eeeeee;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.7);
}
       
.contenedorBoton p{
    font-size: 2rem;
    text-decoration: none;
    
}

.contenedorBoton:hover {
    transition: 0.5s;
    scale: 1.02;
    cursor: pointer;
    color: #2c666e;
    background-color: #d1d1d1;
}

.loading{
    color: white;
    font-size: 4.6rem;
}