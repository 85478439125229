.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 45%;
    height: 35%;

    .header{

        width: 98%;
        height: 40%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3{
            color: #464545;
            font-size: 2.2rem;
            font-weight: 300;
            text-transform: capitalize;
        }

        .rating{
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;


            img{
                height: 65%;
            }

            p{
                color: #464545;
                font-size: 2.6rem;
                font-weight: 300;
            }

        }
    }

    .data{
        transition: 0.5s;
        position: relative;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);

        .info{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            height: 100%;
            z-index: 2;
            position: absolute;

            .user{
                display: flex;
                width: 80%;
                height: 100%;

                .image{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30%;
                    height: 100%;

                    img{
                        border: 5px solid #46454544;
                        border-radius: 50%;
                        width: 65%;
                        height: 70%;
                        object-fit: cover;
                    }
                }
                .imagePremium{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30%;
                    height: 100%;
                    img{
                        border: 4px solid #fdc644;
                        box-shadow: 0px 0px 27px 7px #FFF780;
                        border-radius: 50%;
                        width: 65%;
                        height: 70%;
                        object-fit: cover;
                    }
                }

                .userinfo{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 70%;
                    height: 100%;

                    h3{
                        width: 100%;
                        font-size: 3.0rem;
                        font-weight: 300;
                        color: #F0EDEE;
                        overflow:hidden;
                        white-space:nowrap;
                        text-overflow: ellipsis;
                    }

                    p{
                        font-size: 1.8rem;
                        font-weight: 200;
                        color: #464545;
                    }
                }

            }

            .detail{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20%;
                height: 100%;
                
                .detailLink{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
                .btn{
                    transition: 0.5s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 65%;
                    height: 55%;
                    border-radius: 50%;
                    background-color: #07393C;
                    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.3);
                    img{
                        width: 50%;
                    }

                    &:hover{
                        transition: 0.5s;
                        cursor: pointer;
                        background-color: #0e4e52;
                    }
                }

                .btnPremium{
                    transition: 0.5s;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 65%;
                    height: 55%;
                    border-radius: 50%;
                    background-color: rgb(182, 160, 37);
                    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.3);
                    img{
                        width: 50%;
                    }

                    &:hover{
                        transition: 0.5s;
                        cursor: pointer;
                        background-color: rgb(155, 135, 25);
                    }
                }

            }
        }

        .top{
            z-index: 1;
            position: absolute;
            border-radius: 5px 5px 0 0;
            width: 100%;
            height: 50%;
            background-color: #2C666E;
        }

        .topPremium{
            z-index: 1;
            position: absolute;
            border-radius: 5px 5px 0 0;
            width: 100%;
            height: 50%;
            background-color: #b3ab3c;
        }

        .bottom{
            z-index: 1;
            position: absolute;
            border-radius: 0 0 5px 5px;
            top: 50%;
            width: 100%;
            height: 50%;
            background-color: #F0EDEE;
        }

        &:hover{
            transition: 0.5s;
            scale: 1.02;
        }
    }


}

@media screen and (max-width: 1100px){
	.card{
        min-height: 35%;
        min-width: 85%;
        .data{
            .info{
                .user{
                    .image{
                       width: 38.5%; 
                    }
                    .imagePremium{
                        width: 38.5%; 
                     }
                }
            }
        }
	}
}

@media screen and (max-width: 650px){
	.card{
        min-height: 35%;
        min-width: 85%;
        .data{
            .info{
                .user{
                    .image{
                       width: 38.5%; 
                    }
                    .imagePremium{
                        width: 38.5%; 
                     }
                }
            }
        }
	}
}

@media screen and (max-width: 500px){
	.card{
        min-height: 35%;
        min-width: 90%;
        .data{
            .info{
                .user{
                    .userinfo{
                        h3{
                            font-size: 2.2rem;
                        }
                    }
                }
            }
        }
    }
}