@import "config";

.container {
  animation: init 0.3s;
  width: 100%;
  height: 100%;
  background-image: url(../assets/background.svg);
  color: $dark-grayish-blue;
  font-family: Poppins;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.font-weight-bold {
  font-weight: 700;
}

@keyframes init {
  0%{
    opacity: 0;
    transform: translateY(5vh);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

@import "component";
@import "faq";
@import "animation";
