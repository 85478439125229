.container{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dfdddd;

    .burgerbtn{
        display: none;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 100%;
        z-index: 10;
    }

    .burgerInactive{
        transition: .5s;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        transform: translate(-100%, 0);
    }

    .burgerActive{
        transition: .5s;
        position: absolute;
        top: 0;
        z-index: 9;
        width: 100%;
        box-shadow: 26px 0px 15px -3px rgba(0,0,0,0.1);
        background-color: rgb(236, 236, 236);
        height: 100%;
        transform: translate(0, 0);
    }

    .link{
        transition: 0.5s;
        height: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: black;
        text-decoration: none;
        font-size: 1.6rem;
        margin-inline: 3%;
        border-bottom: 3px solid #dfdddd;

        .notification{
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: rgba(250, 65, 65, 0.7);
            position: absolute;
            top: 14px;
            right: -10px;
        }

        .notificationsChat{
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: rgba(250, 65, 65, 0.7);
            position: absolute;
            top: 10px;
            right: 10px;
        }

        &:hover{
            cursor: pointer;
            transition: 0.5s;
            border-color: #2C666E;
        }

    }

    .menu{
        width: 35%;
        height: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 2%;

    }

    .logo{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        img{
            width: 100%;
            height: 80%;
        }
    }

    .profile{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 25%;
        height: 100%;

        .user{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90%;
            height: 90%;

            .link{
                width: 25%;
                height: 90%;
                display: flex;
                font-size: 1.8rem;
                justify-content: space-around;
                align-items: center;

                img{
                    width: 50%;
                }
            }

            .profileimg{
                transition: 0.5s;
                width: 25%;
                height: 90%;
                display: flex;
                justify-content: center;
                align-items: center;
                object-fit: cover;

                img{
                    width: 65%;
                    height: 70%;
                    border: 5px solid #c9c9c997;
                    border-radius: 50%;
                    object-fit: cover
                }

                &:hover{
                    transition: 0.5s;
                    scale: 1.02;
                }
            }
            .profileimgPremium{
                transition: 0.5s;
                width: 15%;
                height: 60%;
                margin-left: 5%;
                border-radius: 50%;
                border: 2px solid #fdc644;
                box-shadow: 0px 0px 27px 7px #FFF780;
                background-color: #c9c9c971;
                object-fit: cover;

                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover
                }

                &:hover{
                    transition: 0.5s;
                    scale: 1.02;
                }
            }

            .logout{
                margin-left: 5%;
            }
        }

        .nouser{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 90%;
            height: 90%;

            .link{
                margin-right: 10%;
                width: 45%;
                height: 90%;
                display: flex;
                font-size: 1.8rem;
                justify-content: space-around;
                align-items: center;

                img{
                    width: 20%;
                }
            }
        }
    }

    .burgerMenu{
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .container{
        .menu{
            width: 35%;
        }

        .profile{
            width: 35%;
        }
    }
}


@media screen and (max-width: 900px) {
    .container{
        flex-direction: row;
        .menu{
            display: none;
        }
        .logo{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-right: 20px;

            img{
                width: 100px;
                height: 90%;
            }
        }
        .profile{
            display: none;
        }

        .burgerbtn{
            display: flex;
        }
    }
}