.container {
    animation: init 0.8s;
    width: 100%;
	height: 90%;
    display: flex;

    .maptop{
        display: none;
    }

    .aside{
        width: 60%;
        height: 100%;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
		flex-direction: column;
        background-color: #eaeaea;

        .link{
            text-decoration: none;
        }

        .presentation{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 90%;
            height: 30%;
            padding-left: 3.5%;
            padding-right: 3%;
            padding-bottom: 1.5%;

            .title{
                font-weight: 400;
                font-size: 3rem;
            }

            .text{
                font-weight: 300;
                font-size: 1.9rem;
            }
        }

        .destacados{
            min-width: 100%;
            min-height: 70%;
            display: flex;
		    justify-content: center;
		    align-items: center;
		    flex-direction: column;

            .subtitle{
                padding-bottom: 2%;
                font-weight: 300;
                font-size: 3.5rem;
            }

            .cards {
                display: flex;
                justify-content: center;
                margin-right: 4%;
                width: 95%;
                min-height: 70%;
                margin-bottom: 3%;

                .notFind{
                    margin-right: -4%;
                    padding-top: 12%;
                    padding-bottom: 10%;
                    font-weight: 200;
                    font-size: 2.7rem;
                    text-shadow: 0 0 3px rgb(51, 108, 114), 0 0 3px rgb(53, 102, 119), 0 0 8px rgb(103, 233, 201);
                }
            }

            .button{
                transition: 0.5s;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 220px;
                height: 46px;
                border-radius: 5px;
                background-color: #2c666e;
                color: #eeeeee;

                p {
                    font-size: 2rem;
                    text-decoration: none;
                }

                &:hover {
                    transition: 0.5s;
                    scale: 1.02;
                    cursor: pointer;
                    color: #2c666e;
                    background-color: #d1d1d1;
                }
            }
        }
    }
}



.container2{
    background-image: url(assets/mapa_fondo.svg);
    width: 40%;
    min-height: 100%;
    background-size: cover;
	display: flex;
	flex-direction: column;
    justify-content: center;
	align-items: center;

    .link{
        text-decoration: none;
    }

    .titlemapa{
        margin-left: 16%;
        margin-bottom: 15%;
        font-weight: 400;
        font-size: 6rem;
        text-decoration: wavy;
        text-transform: capitalize;
        text-shadow: 0 0 3px rgb(51, 108, 114), 0 0 3px rgb(53, 102, 119), 0 0 8px rgb(103, 233, 201);
    }

    .button{
        transition: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 46px;
        border-radius: 5px;
        background-color: #2c666e;
        color: #eeeeee;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.7);

        p {
            font-size: 2rem;
            text-decoration: none;
        }

        &:hover {
            transition: 0.5s;
            scale: 1.02;
            cursor: pointer;
            color: #2c666e;
            background-color: #d1d1d1;
        }
    }
}


@media screen and (max-width: 1200px) {
    .container{
        flex-direction: column;
        overflow-y: scroll;

        .aside{
            width: 100%;
        }

        .maptop{
            background-image: url(assets/mapa_fondo.svg);
            width: 100%;
            min-height: 100%;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .link{
                text-decoration: none;
            }

            .titlemapa{
                margin-left: 16%;
                margin-bottom: 15%;
                font-weight: 400;
                font-size: 6rem;
                text-decoration: wavy;
                text-transform: capitalize;
                text-shadow: 0 0 3px rgb(51, 108, 114), 0 0 3px rgb(53, 102, 119), 0 0 8px rgb(103, 233, 201);
            }

            .button{
                transition: 0.5s;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 220px;
                height: 46px;
                border-radius: 5px;
                background-color: #2c666e;
                color: #eeeeee;
                box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.7);

                p {
                    font-size: 2rem;
                    text-decoration: none;
                }

                &:hover {
                    transition: 0.5s;
                    scale: 1.02;
                    cursor: pointer;
                    color: #2c666e;
                    background-color: #d1d1d1;
                }
            }

            .titlemapa{
                text-align: center;
                margin: 0;
                margin-bottom: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90%;
            }
        }

        .container2{
            display: none;
        }
    }
}

@media screen and (max-width: 900px) {
    .container{
        min-height: 100%;
        overflow-y: scroll;

        .aside{
            justify-content: space-evenly;

            .presentation{
                .title{
                    font-size: 2.4rem;
                }
                .text{
                    text-align: justify;
                    font-size: 1.8rem;
                }
            }

            .destacados{
                min-height: 5%;
                .subtitle{
                    display: none;
                }

                .cards{
                    display: none;
                }

            }
        }

        .maptop{

            .titlemapa{
                font-size: 3.8rem;
            }
        }
    }
}

@keyframes init {
    0%{
        opacity: 0;
      transform: translateY(5vh);
    }
    100%{
        opacity: 1;
      transform: translateY(0);
    }
  }