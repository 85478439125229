.container{
    animation: init .8s;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 90%;

    .profContainer, .clientContainer{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 40%;
        height: 80%;
        border-radius: 10px;
        background-color: rgb(193, 193, 193);
        box-shadow: 14px 7px 42px -3px rgba(0,0,0,0.1);
        overflow-y: scroll;

        h4{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 3.2rem;
            font-weight: 300;
            width: 100%;
            min-height: 15%;
            color: #1f1f1f;
        }

        p{
            font-size: 1.6rem;
        }
    }
}

//smarthphones
@media screen and (max-width: 700px) {

    .container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .profContainer, .clientContainer{
            width: 90%;
            height: 45%;

            h4{
                font-size: 2.8rem;  
            }

            p{
                font-size: 1.2rem;
            }
        }
    }
}

@keyframes init {
    0%{
        opacity: 0;
      transform: translateY(5vh);
    }
    100%{
        opacity: 1;
      transform: translateY(0);
    }
  }
