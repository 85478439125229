.container {
  animation: init 0.3s;
  height: 90%;
  width: 100%;
  display: flex;
  background: rgba(217, 217, 217, 0.2);

  .leftContainer {
    margin-top: 1.5%;
    margin-bottom: 1%;
    margin-right: -2.5%;
    height: 97.5%;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .profileInfo {
      min-height: 35%;
      display: flex;
      flex-direction: row;
      width: 90%;

      .profile_Img_container {
        display: flex;
        width: 30%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .btnCoordinate {
          transition: 0.5s;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          width: 70%;
          height: 20%;
          margin-top: 3%;
          background-color: rgba(105, 105, 105, 0.545);
          background-color: #2C666E;
          color: #D9D9D9;
          font-size: 1.8rem;
          box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);

          &:hover {
            transition: 0.5s;
            cursor: pointer;
            color: #2C666E;
            background-color: #EEEEEE;
          }
        }

        .btnReport{
          transition: 0.5s;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          width: 70%;
          height: 20%;
          margin-top: 4%;
          background-color: rgba(105, 105, 105, 0.545);
          font-size: 1.8rem;

          &:hover{
            transition: 0.5s;
            cursor: pointer;
            background-color: #d55a5a;
            color: #EEEEEE;
          }
        }

        .profile_Img {
          width: 55%;
          height: 55%;
          border-radius: 50%;
          border: 15px solid rgba(0, 0, 0, 0.041);
          object-fit: cover;
        }
      }

      .profile_Img_containerPremium {
        display: flex;
        width: 30%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .btnCoordinate {
          transition: 0.5s;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          width: 60%;
          height: 20%;
          margin-top: 3%;
          background-color: rgba(105, 105, 105, 0.545);
          background-color: #2C666E;
          color: #EEEEEE;
          font-size: 1.8rem;

          &:hover {
            transition: 0.5s;
            cursor: pointer;
            background-color: rgba(105, 105, 105, 0.545);
            color: black;
          }
        }

        .btnReport{
          transition: 0.5s;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          width: 70%;
          height: 20%;
          margin-top: 4%;
          background-color: rgba(105, 105, 105, 0.545);
          font-size: 1.8rem;

          &:hover{
            transition: 0.5s;
            cursor: pointer;
            background-color: #d55a5a;
            color: #EEEEEE;
          }
        }

        .profile_ImgPremium {
          width: 55%;
          height: 55%;
          border-radius: 50%;
          border: 9px solid #fdc644;
          box-shadow: 0px 0px 27px 7px #FFF780;
          object-fit: cover;
        }
      }

      .profileDetail {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-items: center;
        width: 70%;
        height: 100%;

        .name {
          text-transform: capitalize;
          font-size: 4.4rem;
          line-height: 5rem;
          color: #000000;
        }

        .namePremium {
          display: flex;
          justify-content: flex-start;
          text-transform: capitalize;
          align-items: center;
          font-size: 4.4rem;
          line-height: 5rem;
          color: #000000;
        }

        .corona {
          width: 50px;
          height: 50px;
          margin-right: 5px;

        }

        .location {
          font-size: 2.3rem;
          line-height: 3rem;
          /* identical to box height */
          color: rgba(0, 0, 0, 0.58);
          margin-bottom: 2rem;
        }

        .description {
          font-weight: 200;
          text-align: justify;
          font-size: 1.7rem;
          line-height: 3rem;
          color: #000000;
          width: 95%;
          height: 45%;
        }
      }

    }

    .orderBox {
      margin-top: 3%;
      height: 45%;
      width: 90%;
      display: flex;
      flex-direction: column;
      // flex-wrap: wrap;
      align-content: space-around;
      // justify-content: space-evenly;
      border-radius: 10px;
      background: #D9D9D9;

      .orderText {
        font-weight: 300;
        margin-top: 2%;
        margin-left: 4.9%;
        font-size: 3.2rem;
        line-height: 5.4rem;
        color: #000000;
      }

      .lastOrders {
        overflow-y: auto;
        height: 70%;
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 5%;
        padding-right: 5%;
        align-items: center;
        justify-content: space-between;
      }

    }

    .configBox {
      display: flex;
      margin-bottom: 3%;
      margin-top: 2%;
      height: 10%;
      width: 100%;
      justify-content: left;
      align-items: center;

      img {
        margin-left: 5%;
        width: 3.5%;
      }

      div {
        margin-left: 1%;
        font-size: 1.5rem;
      }
    }

  }
}


.rightContainer {
  // border: solid black;
  margin-top: 1.5%;
  margin-bottom: 1%;
  height: 97.5%;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .professionContainer {
    height: 45%;
    width: 90%;
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    align-content: space-around;
    // justify-content: space-evenly;
    border-radius: 10px;
    background: #D9D9D9;

    .professionText {
      font-weight: 300;
      margin-top: 2%;
      margin-left: 4.9%;
      font-size: 3.2rem;
      line-height: 5.4rem;
      color: #000000;
    }

  }

  .professionContainerPremium{
    height: 25%;
    width: 90%;
    
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    align-content: space-around;
    // justify-content: space-evenly;
    border-radius: 10px;
    background: #D9D9D9;

    .professionText {

      font-weight: 300;
      margin-top: 2%;
      margin-left: 4.9%;
      font-size: 3.2rem;
      line-height: 5.4rem;
      color: #000000;
    }
  }

  .moreReviews {
    height: 35%;
    margin-bottom: 15.4%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-content: center;
    border-radius: 10px;
    background: #D9D9D9;

    .premiumText {
      width: 80%;
      height: 20%;


      h1 {
        font-weight: 300;
        margin-top: 4%;
        margin-left: 12%;
        margin-bottom: 2%;
        font-size: 3.2rem;
        line-height: 3.0rem;
        color: #000000;
      }

    }

    .OtherReviews {
      width: 95%;
      height: 80%;
      margin-left: 2.5%;
      display: flex;
    }
  }
  .moreImages{
    height: 55%;
    margin-bottom: 15.4%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-content: center;
    border-radius: 10px;
    background: #D9D9D9;
    box-shadow: 0px 0px 6px 4px #FFF780;
    .premiumText {
      width: 90%;
      height: 20%;
      text-align: center;

      h1 {
        font-weight: 300;
        margin-top: 4%;
        margin-left: 12%;
        margin-bottom: 2%;
        font-size: 3.2rem;
        line-height: 3.0rem;
        color: #000000;
      }

    }

    .OtherReviews {
      width: 95%;
      height: 80%;
      margin-left: 2.5%;
      display: flex;
    }
  }

  .poorMoreReviews {
    height: 35%;
    margin-bottom: 15.4%;
    width: 90%;
    display: none;
    flex-direction: column;
    align-content: center;
    border-radius: 10px;
    background: #D9D9D9;

    .premiumText {
      width: 80%;
      height: 20%;


      h1 {
        font-weight: 300;
        margin-top: 4%;
        margin-left: 12%;
        margin-bottom: 2%;
        font-size: 3.2rem;
        line-height: 3.0rem;
        color: #000000;
      }

    }

    .OtherReviews {
      width: 95%;
      height: 80%;
      margin-left: 2.5%;
      display: flex;
    }
  }
}


//Responsive Tablets  -----------------------------------------
@media screen and (max-width: 1100px) {
  .container {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    .leftContainer {
      width: 80%;
      min-height: 65vh;
      margin-right: 0;
      height: auto;

      .profileInfo {
        height: 40%;
        width: 100%;
        margin-top: 2%;
        margin-bottom: 3%;

        .profileDetail {
          width: 70%;

          .name {
            text-transform: capitalize;
            font-size: 4.4rem;
            line-height: 5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #000000;
          }

          .corona {
            width: 50px;
            height: 50px;
            margin-right: 8px;
          }

          .description {
            font-weight: 280;
            font-size: 2rem;
          }
        }

        .profile_Img_container {
          min-width: 45%;
          height: 100%;

          .btnCoordinate {
            width: 70%;
            height: 25%;
            font-size: 2.5rem;
            margin-bottom: 2%;
            margin-top: 2%;
          }

          .btnReport{
            width: 70%;
            height: 25%;
            font-size: 2.5rem;
            margin-bottom: 2%;
            margin-top: 2%;
          }

          .profile_Img {
            width: 40%;
            height: 50%;
          }
        }

        .profile_Img_containerPremium {
          min-width: 45%;
          height: 100%;

          .btnCoordinate {
            width: 70%;
            height: 25%;
            font-size: 2.5rem;
            margin-bottom: 2%;
            margin-top: 2%;
          }

          .btnReport{
            width: 70%;
            height: 25%;
            font-size: 2.5rem;
            margin-bottom: 2%;
            margin-top: 2%;
          }

          .profile_ImgPremium {
            width: 55%;
            height: 55%;
          }
        }
      }




      .orderBox {
        display: flex;
        height: 60%;
        width: 100%;

        .lastOrders {
          // flex-direction: column;
          height: 85%;
          justify-content: space-evenly;
          overflow-y: auto;
        }

      }

      .configBox {
        display: none;
      }
    }

    .rightContainer {
      margin-top: 5%;
      width: 80%;
      min-height: 75vh;

      .professionContainer {
        width: 100%;
        margin-bottom: 5%;

        .noProfessional {
          height: 80%;
          width: 80%;
          margin-left: 10%;
          display: flex;
          justify-content: center;
          font-size: 3.8rem;

          .intNoProf {
            height: 30%;
            width: 95%;
          }
        }
      }

      .professionContainerPremium{
        width: 100%;
      }

      .isPremiumTRUE {
        height: 20vh;
        width: 100%;

        h1 {
          font-weight: 300;
          margin-top: 2%;
          margin-left: 6%;
          font-size: 4rem;
          line-height: 5.4rem;
        }

        .premiumDetailTRUE {
          h4 {
            font-size: 1.8rem;
          }
        }
      }
      .moreImages{
        width: 100%;
      }
      .moreReviews {
        height: 35%;
        margin-bottom: 15.4%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        border-radius: 10px;
        background: #D9D9D9;
    
        .premiumText {
          width: 80%;
          height: 20%;
    
    
          h1 {
            font-weight: 300;
            margin-top: 4%;
            margin-left: 12%;
            margin-bottom: 2%;
            font-size: 3.2rem;
            line-height: 3.0rem;
            color: #000000;
          }
    
        }
    
        .OtherReviews {
          width: 95%;
          height: 80%;
          margin-left: 2.5%;
          display: flex;
        }
      }
    }
  }
}

//Responsive smarthphones  -----------------------------------------
@media screen and (max-width: 700px) {
  
  .container {

    .leftContainer {
      width: 90%;

      .profileInfo {
        height: 40%;
        width: 90%;
        margin-top: 2%;
        margin-bottom: 3%;

        .profileDetail {
          width: 100%;

          .name {
            margin-left: -7%;
            font-size: 2.5rem;
            line-height: 3rem;
          }

          .location {
            margin-left: -5%;
            font-size: 1.9rem;
            line-height: 2.5rem;
          }

          .description {
            margin-top: -3%;
            margin-bottom: 3%;
            font-weight: 280;
            font-size: 1.8rem;
          }
        }

        .profile_Img_container {
          margin-left: -6%;
          margin-right: 4%;

          .btnCoordinate {
            width: 90%;
            font-size: 2rem;
            margin-bottom: 5%;
            margin-top: 4%;
          }

          .btnReport{
            width: 90%;
            height: 25%;
            font-size: 2rem;
            margin-top: 2%;
          }

          .profile_Img {
            width: 55%;
            height: 50%;
          }
        }

        .profile_Img_containerPremium {
          margin-left: -6%;
          margin-right: 4%;

          .btnCoordinate {
            width: 90%;
            font-size: 2rem;
            margin-bottom: 5%;
            margin-top: 4%;
          }

          .btnReport{
            width: 90%;
            height: 25%;
            font-size: 2rem;
            margin-top: 2%;
          }

          .profile_ImgPremium {
            width: 55%;
            height: 50%;
          }
        }
      }

      .orderBox {
        display: flex;
        justify-content: center;
        
        .orderText{
          margin: 0 auto;
          font-size: 2.7rem;
        }

        .lastOrders {
          // flex-direction: column;
          height: 85%;
          justify-content: space-evenly;
          overflow-y: auto;
        }
      }

      .configBox {
        display: none;
      }
    }

    .rightContainer {
      width: 90%;

      .professionContainer {
        width: 100%;
        margin-bottom: 5%;

        .professionText {
          margin: 0 auto;
          font-size: 2.7rem;
          line-height: 5.4rem;
        }
      }

      .professionContainerPremium {
        width: 100%;
        margin-bottom: 5%;

        .professionText {
          margin: 0 auto;
          font-size: 2.7rem;
          line-height: 5.4rem;
        }
      }


      .isPremiumTRUE {
        height: 20vh;
        width: 100%;

        h1 {
          font-weight: 300;
          margin-top: 2%;
          margin-left: 6%;
          font-size: 4rem;
          line-height: 5.4rem;
        }

        .premiumDetailTRUE {
          h4 {
            font-size: 1.8rem;
          }
        }
      }

      .moreReviews {
        height: 35%;
        margin-bottom: 25%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        border-radius: 10px;
        background: #D9D9D9;
    
        .premiumText {
          width: 80%;
          height: 20%;
          margin: 0 auto;
    
          h1 {
            font-size: 2.7rem;
          }
        }
    
        .OtherReviews {
          width: 95%;
          height: 80%;
          margin-left: 2.5%;
          display: flex;
        }
      }
    }
  }
}

@keyframes init {
  0%{
      opacity: 0;
    transform: translateY(5vh);
  }
  100%{
      opacity: 1;
    transform: translateY(0);

  }
}
