$white-color: #F0EDEE;
$green-color: #0e4e52;
$lightgreen-color: #2C666E;
$darkgreen-color: #07393C;

.cardContainer {
  border: 1px solid black;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $green-color;
  border-radius: 10px;
  width: 90%;
  height: 60px;
  transition: height 1s;
  overflow: hidden;
  
  .importantInformationContainer, .notSoRelevantInformationContainer {
      display: flex;
      color: $white-color;
      justify-content: space-evenly;
      align-items: center;
      gap: 10px;

   div {
       display: flex;
       flex-direction: column;
       align-items: center;
    }
   .nameContainer {
       h1 {
          font-size: 1rem;
          text-overflow: ellipsis;
       }
    }
    
   .deleteBtn {
      width: 80px;
      height: 30px;
      border-radius: 5px;
      color: $white-color;
      background-color: rgba(175, 43, 43, 0.829);
         &:hover{
            color: $white-color;
            background-color: rgb(190, 39, 39);
         }
   }

   .editBtn {
      width: 80px;
      height: 30px;
      border-radius: 5px;
      color: $white-color;
      background-color: rgba(128, 128, 240, 0.534);
         &:hover{
            color: $white-color;
            background-color: rgba(92, 92, 238, 0.534);
         }
   }
 }
}