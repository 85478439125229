.container {
	animation: init 1s;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.login {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;
		width: 40%;
		height: 100%;
		background-color: #eeeeee;
		box-shadow: 20px 0px 40px 0px rgba(0, 0, 0, 0.286);

		.logo {
			width: 75%;
			margin-bottom: 15%;
			img {
				width: 100%;
				filter: drop-shadow(5px 5px 5px rgba(34, 34, 34, 0.385));
			}
		}

		.login_btn {
			transition: 0.5s;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40%;
			height: 7%;
			border-radius: 5px;
			background-color: #2c666e;
			color: #eeeeee;

			p {
				font-size: 2rem;
			}

			&:hover {
				transition: 0.5s;
				scale: 1.02;
				cursor: pointer;
				color: #2c666e;
				background-color: #d1d1d1;
			}
		}

		.invite {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 8%;

			.link {
				transition: 0.5s;
				font-size: 1.6rem;
				color: #464545bf;
				text-decoration: underline;

				&:hover {
					transition: 0.5s;
					scale: 1.02;
					cursor: pointer;
					color: #626161bf;
				}
			}
		}

		.ayuda {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 5%;

			.link {
				transition: 0.5s;
				font-size: 1.6rem;
				color: #464545bf;
				text-decoration: underline;

				&:hover {
					transition: 0.5s;
					scale: 1.02;
					cursor: pointer;
					color: #626161bf;
				}
			}
		}
	}

	.description {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		z-index: 1;
		background-color: #43787fa9;
		width: 60%;
		min-width: 60%;
		height: 100%;

		img {
			z-index: 1;
			position: absolute;
			mix-blend-mode: multiply;
			object-fit: cover;
			width: 60%;
			height: 100%;
		}

		.text {
			margin-inline: 5%;
			width: 90%;
			height: 90%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			z-index: 2;
			color: #eeeeee;

			h4 {
				font-size: 6.2rem;
				font-weight: 200;
				margin-bottom: 5%;
				text-shadow: 3px 2px 7px rgba(0, 0, 0, 0.44);
			}

			p {
				text-shadow: 3px 2px 7px rgba(0, 0, 0, 0.44);
				z-index: 2;
				color: #eeeeee;
				font-size: 2.8rem;
				font-weight: 200;
			}
		}
	}
}

//tablets
@media screen and (max-width: 1100px) {

	.container{
		.login{
			width: 50%;
		}



		//description
		.description {
			width: 50%;
			.text {
				h4 {
					font-size: 4.2rem;
				}

				p {
					font-size: 2.2rem;
				}
			}
		}
	}
    //do Smth
  }

 //small tablets
  @media screen and (max-width: 850px) {

	.container{
		.login{
			width: 100%;
		}

		//description
		.description {
			display: none;
		}
	}
    //do Smth
  }

  @keyframes init {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
  }

