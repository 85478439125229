$white-color: #F0EDEE;
$green-color: #0e4e52;
$lightgreen-color: #2C666E;
$darkgreen-color: #07393C;

.container {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* From https://css.glass */
  background: rgba(24, 24, 24, 0.40);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(24, 24, 24, 0.3);

    .infoContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 50%;
      height: 50%;
      border-radius: 12px;
      background-color: white;

        .btnContainer{
          display: flex;
          justify-content: space-evenly;
          width: 60%;
          
          .deleteBtn {
            width: 80px;
            height: 30px;
            border-radius: 5px;
            color: $white-color;
            background-color: rgba(175, 43, 43, 0.829);
               &:hover{
                  color: $white-color;
                  background-color: rgb(190, 39, 39);
               }
          }

          .cancelBtn {
            width: 80px;
            height: 30px;
            border-radius: 5px;
            color: $white-color;
            background-color: $green-color;
               &:hover{
                  color: $white-color;
                  background-color: #0e4e52c5;
               }
          }
        }
    }
}