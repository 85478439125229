.professionComponent {
  background: #F0EDEE;
  margin-top: 3%;
  margin-bottom: 3%;
  height: 35%;
  max-height: 150px;
  width: 80%;
  max-width: 300px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  .professionAndDescription {
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    h1 {
      font-weight: 300;
      font-size: 2.7rem;
      line-height: 3.5rem;
      text-transform: capitalize;
    }
  }

}

@media screen and (max-width: 1100px) {
  .professionComponent{
    .professionAndDescription{
      h1{
        font-size: 3.4rem;
      }
    }
  }
}