.orders {
  width: 80%;
  height: 40%;
  max-height: 150px;
  background: #0a4f52d3;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  .imgDetail {
    width: 15%;
    height: 60%;
    margin-left: 3%;
    margin-right: 5%;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .orderDetail {
    width: 55%;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    h1 {
      font-weight: 230;
      font-size: 2.3rem;
      line-height: 3rem;
      color: #F0EDEE;
    }

    h2 {
      height: 80%;
      width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: justify;
      font-weight: 230;
      font-size: 1.5rem;
      line-height: 2rem;
      color: rgba(217, 217, 217, 0.63);
    }

  }

  .orderDetail2 {
    width: 20%;
    margin-right: -5%;

    img{
      margin-bottom: 2px;
      margin-right: 10px;
    }
    h3 {
      margin-left: 2%;
      display: flex;
      font-weight: 230;
      font-size: 2.5rem;
      line-height: 5.4rem;
      color: #F0EDEE;
    }
  }

  &:hover {
    transition: 0.5s;
    scale: 1.04;
  }
}