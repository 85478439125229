.outerContainer {
  height: 90%;
  width: 100%;
  display: flex;
  background: rgba(231, 231, 231, 0.753);
  justify-content: center;
  align-items: center;

  .innerContainer {
    height: 90%;
    width: 50%;
    display: flex;
    background: rgba(255, 255, 255, 0.596);
    border: solid rgba(136, 136, 136, 0.356);
    border-width: 2px;

    .leftContainer {
      // border: solid red;
      height: 100%;
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.596);
      border-right: solid rgba(136, 136, 136, 0.356);
      border-width: 2px;

      .optionList {
        // border: solid red;
        height: 10%;
        width: 100%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        transition: 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: black;
        text-decoration: none;
        font-size: 1.6rem;
        margin-inline: 3%;
        // border-right: 3px solid #2C666E;
        background-color: rgba(255, 255, 255, 0.596);

        &:hover {
          cursor: pointer;
          transition: 0.2s;
          border-right: 3px solid #2c666e7a;
          background-color: rgba(197, 197, 197, 0.274);
        }
      }

      .activeOptionList {
        height: 10%;
        width: 100%;
        padding-left: 2.5%;
        padding-right: 2.5%;
        transition: 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: black;
        text-decoration: none;
        font-size: 1.6rem;
        margin-inline: 3%;
        // border-right: 3px solid #2C666E;
        background-color: rgba(197, 197, 197, 0.274);
        border-right: 3px solid #2c666e7a;
        
        &:hover {
          cursor: pointer;
          transition: 0.2s;
          border-right: 3px solid #2c666eaa;
          background-color: rgba(155, 155, 155, 0.274);
        }
      }
    }


    .rightContainer {
      // border: solid black;
      height: 100%;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

// TABLETS -----> IPAD AIR
@media screen and (max-width: 1100px) {
  .outerContainer {
    .innerContainer {
      overflow-y: auto;
      width: 100%;
      height: 100%;
      .leftContainer{
        width: 20%;
        .optionList{
          font-size: 2rem;
        }
        .activeOptionList{
          font-size: 2rem;
        }
      }
      .rightContainer{
        width: 80%;
      }
    }
  }
}