.orders {
  width: 95%;
  height: 40%;
  background: #07393C;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
  margin-top: 3%;
  margin-bottom: 3%;
  min-height: 35%;
  min-width: 100%;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  .imgDetail {
    width: 10%;
    height: 75%;
    margin-left: 3%;
    margin-right: 3%;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .orderDetail {
    width: 70%;
    display: flex;
    flex-direction: column;


    h1 {
      text-transform: capitalize;
      font-weight: 230;
      font-size: 1.5em;
      line-height: 2rem;
      color: #F0EDEE;
    }

    h2 {
      height: 90%;
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: justify;
      font-weight: 280;
      font-size: 2.2rem;
      line-height: 3rem;
      color: rgba(217, 217, 217, 0.63);
    }

  }

  .orderDetail2 {
    width: 15%;
    margin-right: -5%;

    h3 {
      display: flex;
      font-weight: 230;
      font-size: 2.5rem;
      line-height: 5.4rem;
      color: #F0EDEE;
    }
  }

  &:hover {
    transition: 0.5s;
    scale: 1.04;
  }
}


// TABLET ----> IPAD AIR
@media screen and (max-width: 1100px) {
  .orders {
    width: 95%;
    height: 40%;
    background: #07393C;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
    margin-top: 3%;
    margin-bottom: 3%;
    min-height: 35%;
    min-width: 100%;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;

    .imgDetail {
      width: 18%;
      height: 75%;
      margin-right: 3%;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .orderDetail {
      width: 55%;
      display: flex;
      flex-direction: column;


      h1 {
        font-size: 2rem;
        line-height: 3rem;
      }

      h2 {
        font-size: 2.5rem;
      }

    }

    .orderDetail2 {
      width: 20%;
      margin-right: -5%;

      img {
        margin-right: 10px;
        margin-bottom: 2px;
      }

      h3 {
        font-size: 3rem;
      }
    }

  }
}