$white-color: #F0EDEE;
$green-color: #0e4e52;
$lightgreen-color: #2C666E;
$darkgreen-color: #07393C;

.cardContainer {
  background-color: $white-color;
  color: $green-color;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  transition: height 1s;
  overflow: hidden;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  box-shadow: 14px 19px 31px -3px rgba(0,0,0,0.1);

   .importantInformationContainer {
      background-color: $green-color;
      color: $white-color;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 10px 0;
      gap: 10px;

      .detailsBtn {
         width: 80px;
         height: 30px;
         margin-top: -7px;
         border-radius: 5px;
         color: $darkgreen-color;
         background-color: $white-color;
            &:hover{
               color: $white-color;
               background-color: $darkgreen-color;
            }
      }
      div {
         display: flex;
         flex-direction: column;
         align-items: center;
      }
      .statusBtn {
        width: 80px;
        height: 20px;
        border-radius: 5px;
        margin-bottom: 3px;
        background-color: $lightgreen-color;
        color: $white-color;
         &:hover{
            color: $white-color;
            background-color: $darkgreen-color;
         }
      }
      .yesStatus {
         color: rgba(164, 238, 164, 0.726);
      }
      
      .noStatus {
         color: rgb(218, 129, 129);
      }

      .nameContainer {
         h1 {
            font-size: 1rem;
            text-overflow: ellipsis;
         }
      }
      
      .deleteBtn {
         width: 80px;
         height: 30px;
         border-radius: 5px;
         color: $white-color;
         background-color: rgba(175, 43, 43, 0.829);
            &:hover{
               color: $white-color;
               background-color: rgb(190, 39, 39);
            }
      }

      .editBtn {
         width: 80px;
         height: 30px;
         border-radius: 5px;
         color: $white-color;
         background-color: rgba(128, 128, 240, 0.534);
            &:hover{
               color: $white-color;
               background-color: rgba(92, 92, 238, 0.534);
            }
      }

      .restoreBtn {
         width: 80px;
         height: 30px;
         border-radius: 5px;
         color: $white-color;
         background-color: rgba(37, 168, 37, 0.829);
            &:hover{
               color: $white-color;
               background-color: rgba(60, 138, 60, 0.829);
            }
      }
   }

   .notSoRelevantInformationContainer{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
      padding-top: 15px;
      height: auto;
      font-size: 1.2rem;
         .description {
            width: 200px;
            height: 100px;
            overflow-y: auto;
            font-size: 1.3rem;
            h4{
               width: 100%;
            }
         }
         .userDataContainer{
               display: flex;
               flex-direction: column;
               gap: 20px;
         
               .dniEmailContainer {
                  display: flex;
                  gap: 100px;
                     div{
                        text-align: center;
                  }
               }
               .birthDatePhoneContainer {
                  display: flex;
                  gap: 115px;
                  div{
                     text-align: center;
               }
               }
            }

         .userLocationContainer{
            display: flex;
            flex-direction: column;
            gap: 10px;
      
            .countryCityContainer {
               display: flex;
               gap: 50px;
                  div{
                     text-align: center;
               }
            }
            .addressContainer {
               display: flex;
               gap: 65px;
               div{
                  text-align: center;
            }
            }
         }
      }
}



//Efecto copado para el display del detalle
.openModal {
   height: 200px;
   transition: height 1s;
}