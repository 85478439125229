$white-color: #F0EDEE;
$green-color: #0e4e52;
$lightgreen-color: #2C666E;
$darkgreen-color: #07393C;

.modalMainContainer {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* From https://css.glass */
  background: rgba(24, 24, 24, 0.40);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(24, 24, 24, 0.3);

    .modalContainer {
      width: 50%;
      height: 80%;
      border-radius: 16px;
      background-color: #F0EDEE;
      display: flex;
      
      .informationLeftSide, .informationRightSide {
        display: flex;
        flex-direction: column;
        width: 50%;
          label, input, h4 {
            font-size: 1.7rem;
            width: 80%;
          }
      }

      .informationLeftSide {
        justify-content: space-evenly;
        align-items: center;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 90%;
        }
      }

      .informationRightSide {
        justify-content: space-evenly;
        div {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          width: 90%;
          .yesBtn {

            border-radius: 5px;
            color: $white-color;
            background-color: rgba(37, 168, 37, 0.829);
          }
          .noBtn {
            // width: 80px;
            // height: 30px;
            border-radius: 5px;
            color: $white-color;
            background-color: rgba(175, 43, 43, 0.829);
               &:hover{
                  color: $white-color;
                  background-color: rgb(190, 39, 39);
               }
          }
          .editBtn {
            width: 80px;
            height: 30px;
            border-radius: 5px;
            color: $white-color;
            background-color: rgba(128, 128, 240, 0.534);
              &:hover{
                  color: $white-color;
                  background-color: rgba(92, 92, 238, 0.534);
              }
          }

          .cancelBtn {
              width: 80px;
              height: 30px;
              border-radius: 5px;
              color: $white-color;
              background-color: rgba(175, 43, 43, 0.829);
                 &:hover{
                    color: $white-color;
                    background-color: rgb(190, 39, 39);
                 }
          }
          div {
            display: flex;
            flex-direction: row;

          }
        }
      }
    }
}