.container{
    animation: init 0.8s;
    position: relative;
    display: flex;
    width:100%;
    height: 90%;
    z-index: 1;

    .leftContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 100%;
        background-color: #ffffff;

        .activeUser{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 40%;
            background-color: #EEEEEE;

            h3{
                padding-top: 3%;
                width: 100%;
                height: 20%;
                padding-left: 5%;
                font-size: 3.2rem;
                font-weight: 400;
            }

            .user{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 35%;

                .profileImage{
                    margin-left: 2%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 25%;
                    height: 85%;

                    img{
                        border: 10px solid rgba(95, 94, 94, 0.212);
                        object-fit: cover;
                        border-radius: 50%;
                        width: 60%;
                        height: 70%;
                    }
                }

                .profileImagePremium{
                    margin-left: 2%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 25%;
                    height: 85%;

                    img{
                        border: 8px solid #fdc54488;
                        box-shadow: 0px 0px 27px 7px #FFF780;
                        object-fit: cover;
                        border-radius: 50%;
                        width: 60%;
                        height: 70%;
                    }
                }


                .name{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 73%;
                    height: 100%;
                    font-size: 2.4rem;
                    text-transform: capitalize;
                    color: #222222;
                }
            }

            .buscador{
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                height: 45%;

                .form{
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    .searchbar{
                        transition: 0.3s;
                        width: 50%;
                        height: 30%;
                        border-radius: 10px;
                        border: 2px solid #4c4c4c5c;
                        font-family: Poppins;
                        font-weight: 300;
                        padding-left: 2%;
                        font-size: 1.8rem;

                        &:hover{
                            transition: 0.3s;
                            background-color: #b4b4b4;
                            color: #FFFFFF;
                        }

                        &:focus{
                            outline: none;
                        }

                        &:hover::placeholder{
                            transition: 0.3s;
                            font-weight: 300;
                            color: #ffffff9b;
                        }
                    }

                    .submit, .reset{
                        transition: 0.5s;
                        border-radius: 5px;
                        width: 15%;
                        height: 30%;
                        background-color: #ffffff9b;
                        color: #22312b;

                        &:hover{
                            transition: 0.5s;
                            cursor: pointer;
                            scale: 1.02;
                            color: #ffffff9b;
                            background-color: #545454;
                        }
                    }
                }
            }
        }

        .professionals{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 60%;
            margin-top: 5%;
            font-size: 1.8rem;

            h3{
                font-weight: 300;
                font-size: 2.2rem;
            }

            select{
                margin-bottom: 20px;
            }

            .professionalsContainer{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                margin-top: 5%;
                font-size: 1.8rem;
                overflow-y: scroll;

                .link{
                    transition: 0.5s;
                    margin-bottom: 2%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    color: #373636;
                    width: 100%;
                    min-height: 25%;

                    .profileImage{
                        margin-left: 2%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 27%;
                        height: 60%;

                        img{
                            border: 10px solid rgba(95, 94, 94, 0.163);
                            object-fit: cover;
                            border-radius: 50%;
                            width: 45%;
                            height: 90%;
                        }
                    }

                    .profileImageP{
                        margin-left: 2%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 27%;
                        height: 60%;

                        img{
                            border: 8px solid #fdc54488;
                            box-shadow: 0px 0px 27px 7px #FFF780;
                            object-fit: cover;
                            border-radius: 50%;
                            width: 45%;
                            height: 90%;
                        }
                    }

                    .name{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 73%;
                        height: 100%;
                        text-transform: capitalize;

                        h3{
                            font-size: 2.4rem;
                            font-weight: 300;
                            opacity: 1;
                        }

                        p{
                            font-size: 1.8rem;
                            font-weight: 200;
                            opacity: 0.8;
                        }
                    }

                    &:hover{
                        cursor: pointer;
                        transition: 0.5s;
                        background-color: #363636;
                        color: #b4b4b4;

                        .profileImage{
                            img{
                                border: 10px solid rgba(223, 222, 222, 0.163);
                            }
                        }
                    }
                }
            }
        }
    }

    .mapContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
        height: 100%;

        .map{
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 1300px) {
    .container{
        .leftContainer{
            width: 40%;
        }

        .mapContainer{
            width: 60%;
        }
    }
}

@media screen and (max-width: 900px) {
    .container{
        flex-direction: column;
        .leftContainer{
            width: 100%;
            height: 30%;
            flex-direction: row;

            .activeUser{
                width: 50%;
                height: 100%;

                .user{
                    height: 45%;
                    .name{
                        font-size: 2.0rem;
                    }
                }

                .buscador{
                    height: 60%;
                }
            }

            .professionals{
                width: 50%;
                height: 100%;

                .professionalsContainer{
                    margin-top: 0;
                    flex-direction: row;
                    overflow-y: hidden;

                    .link{
                        border-right: 1px solid #22222231;
                        min-width: 100%;
                        min-height: 100%;

                        .profileImage{
                            display: none;
                        }

                        .profileImageP{
                            display: none;
                        }

                        .name{
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .mapContainer{
            width: 100%;
            height: 70%;
        }
    }
}

@media screen and (max-width: 700px) {
    .container{
        .leftContainer{
            height: 20%;
            .activeUser{
                h3{
                    font-size: 1.4rem;
                }
                .user{
                    margin-top: 5%;
                    height: 65%;
                    .name{
                        font-size: 1.4rem;
                    }

                    .profileImage{
                        height: 70%;
                    }

                    .profileImagePremium{
                        height: 70%;
                    }
                }

                .buscador{
                    height: 30%;
                    margin-bottom: 5%;
                    .form{
                        .searchbar{
                            height: 65%;
                            font-size: 1.0rem;
                        }

                        .submit, .reset{
                            height: 65%;
                            font-size: 0.8rem;
                        }
                    }
                }
            }

            .professionals{

                h3{
                    font-size: 1.4rem;
                }

                .professionalsContainer{
                    .link{
                        .name{
                            h3{
                                font-size: 1.4rem;
                            }
                            p{
                                font-size: 1.2rem;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
        .mapContainer{
            width: 100%;
            height: 80%;

            .map{
                z-index: 1;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .container{
        .leftContainer{
            height: 20%;
            .activeUser{
                h3{
                    display: none;
                }
                .user{
                    margin-top: 5%;
                    height: 45%;
                    .name{
                        font-size: 1.4rem;
                    }

                    .profileImage{
                        height: 70%;
                    }

                    .profileImagePremium{
                        height: 70%;
                    }
                }

                .buscador{
                    height: 55%;
                    margin-bottom: 5%;
                    .form{
                        .searchbar{
                            height: 35%;
                            font-size: 1.0rem;
                        }

                        .submit, .reset{
                            height: 35%;
                            width: 20%;
                            font-size: 0.8rem;
                        }
                    }
                }
            }

            .professionals{

                h3{
                    font-size: 1.4rem;
                }

                .professionalsContainer{
                    margin-bottom: 5px;
                    .link{
                        .name{
                            h3{
                                font-size: 1.4rem;
                            }
                            p{
                                font-size: 1.2rem;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
        .mapContainer{
            width: 100%;
            height: 80%;

            .map{
                z-index: 1;
            }
        }
    }
}

@keyframes init {
    0%{
        opacity: 0;
      transform: translateY(5vh);
    }
    100%{
        opacity: 1;
      transform: translateY(0);
    }
  }