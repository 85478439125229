.component {
  background-color: rgba(255, 255, 255, 1);
  background-repeat: no-repeat;
  box-shadow: 0px 50px 50px -20px rgba(53, 18, 122, 0.497159);
  border-radius: 23px;
  width: 60%;
  height: 80%;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

//tablets
@media screen and (max-width: 1100px) {

	.component{
		width: 70%;
    height: 75%;
  }
}

//smarthphones
@media screen and (max-width: 700px) {
  
  .component{
		width: 90%;
    height: 85%;
    margin-bottom: 18%;
  }
}
