
.container{
  display: flex;
  flex-direction: column;
  padding-top: 20%;
  width: 100%;
  height: 100%;

  .routes{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    height: 40%;

    .link{
      transition: 0.5s;
      width: 50%;
      height: 20%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      color: #222222;
      text-decoration: none;
      font-weight: 300;
      font-size: 3.2rem;
      margin-left: 5%;
 
      &:hover{
          cursor: pointer;
          transition: 0.5s;
          border-color: #2C666E;
      }
    }
  }

  .profileUser{
    display: flex;
    flex-wrap: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 60%;

    .user{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .profile{
          display: flex;
          padding-top: 20px;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          height: 60%;

          .link{
            width: 30%;
            height: 30%;
            display: flex;
            font-size: 2.4rem;
            justify-content: space-around;
            align-items: center;

            img{
                border-radius: 5px;
                width: 40%;
                height: 80%;
            }
        }
        }

        .link{
            width: 45%;
            height: 20%;
            display: flex;
            flex-direction: column;
            color: #222222;
            text-decoration: none;
            font-weight: 300;
            font-size: 2.4rem;
            justify-content: space-between;
            // align-items: center;
            margin-left: 5%;
            margin-bottom: 25%;
            img{
                width: 40%;
                height: 50%;
            }
        }

        .profileimg{
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.5s;
            width: 35%;
            height: 35%;
            margin-left: 5%;
            // background-color: #c9c9c971;
            object-fit: cover;

            img{
                width: 49%;
                height: 100%;
                border: 5px solid #c9c9c997;
                border-radius: 50%;
                object-fit: cover
            }

            &:hover{
                transition: 0.5s;
                scale: 1.02;
            }
        }
        .profileimgPremium{
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.5s;
            width: 35%;
            height: 35%;
            margin-left: 5%;
            // background-color: #c9c9c971;
            object-fit: cover;

            img{
                width: 49%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                border: 2px solid #fdc644;
                box-shadow: 0px 0px 27px 7px #FFF780;
            }

            &:hover{
                transition: 0.5s;
                scale: 1.02;
            }
        }
    }

    .nouser{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        height: 60%;


        .link{
            margin-bottom: 5%;
            margin-left: 5%;
            width: 45%;
            height: 20%;
            display: flex;
            color: #222222;
            font-size: 1.8rem;
            text-decoration: none;
            justify-content: space-around;
            align-items: center;

            img{
                width: 20%;
            }
        }
    }
  }
}


@media screen and (max-width: 550px) {
    .container{
        .profileUser{
            .user{
                .profile{
                    .link{
                        img{
                            width: 60%;
                        }
                    }
                }

                .profileimg{
                    img{
                        width: 50%;
                    }
                }
                .profileimgPremium{
                    img{
                        width: 50%;
                    }
                }
            }
        }
    }
}