@import "config.module";

.container {
  width: 100%;
  height: 100%;
  background-image: url(../assets/background.svg);
  color: $dark-grayish-blue;
  font-family: Poppins;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  @import "component.module";
}

.font-weight-bold {
  font-weight: 700;
}